<template>
<v-card flat class="mt-5">
    <v-card-text>
        <div id="app">
            <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">
                <template v-slot:[`item.image`]="{ item }">
                    <div class="p-2">
                        <v-img :src="lienimage+item.image" :alt="item.libelle" height="50px" width="50"></v-img>
                    </div>
                </template>

                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Souscription ODD</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">

                            <v-snackbar v-model="snackbar" :multi-line="multiLine">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                        Fermer
                                    </v-btn>
                                </template>
                            </v-snackbar>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                                    Nouveau
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-text-field v-model="matricule" label="Matricule Institution*" dense outlined required></v-text-field>

                                            <v-select :items="listcible" v-model="selectcible" @change="fuseSearch" dense item-value="id" outlined label="Liste des cibles">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                            </v-select>

                                            <v-select :items="listindicateur2" v-model="selectindicateur" dense item-value="id" outlined label="Liste des Indicateurs">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                            </v-select>

                                        </v-form>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialog=false">
                                        Fermer
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.indicateurodd.cibles`]="{ item }">
                    {{ item.indicateurodd.cibles.code }} - {{ item.indicateurodd.cibles.description }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" color="green" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon color="red" class="mr-2" small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">
                        Actualiser la liste
                    </v-btn>
                </template>
            </v-data-table>

        </div>

    </v-card-text>
</v-card>
</template>

<script>
import api from './../../../serviceApi/apiService'

export default {
    props: {
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        multiLine: true,
        snackbar: false,
        snackbars: false,
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        headers: [{
                text: 'ID',
                value: 'id',
                divider: true,
                width: '10px'
            },
            {
                text: 'Date',
                align: 'start',
                value: 'created_by',
                divider: true,
                width: '60px'
            },
            {
                text: 'Reference',
                value: 'code',
                divider: true,
                width: '100px'
            },
            // { text: 'Indicateur Odd', align: 'start', sortable: false,value: 'indicateurodd',divider: true,width: '250px'},
            {
                text: 'cible',
                align: 'start',
                sortable: false,
                value: 'indicateurodd.cibles',
                divider: true,
                width: '250px'
            },
            {
                text: 'Matricule Institution',
                align: 'start',
                sortable: false,
                value: 'institutions.matricule',
                divider: true,
                width: '100px'
            },
            {
                text: 'Libelle Institution',
                align: 'start',
                sortable: false,
                value: 'institutions.libelle',
                divider: true,
                width: '250px'
            },

            {
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '50px'
            },
        ],
        formTitle: 'Formulaire de Souscription',
        list: [],
        listcible: [],
        listindicateur: [],
        listindicateur2: [],

        selectcible: '',
        code: '',
        matricule: '',
        selectindicateur: '',
        valid: true,
        id_cibles: '0',
        id_indicateur: '0',
        reference: '',
        moduleodd: 'odd',
        editedIndex: -1,
        textetat: false,
        messages: '',

    }),

    methods: {
        //=====Affichages  liste des odds==========
        readAll: async function () {
            const data = await api.readAll('souscription/all-souscription/odd');
            this.list = data.list;
        },

        readcibleAll: async function () {
            const data = await api.readAll('cibles/all');
            this.listcible = data.list;
            // console.log(this.listcible);
        },

        readindicateurAll: async function () {
            const data = await api.readAll('indicateur-odd/all');
            this.listindicateur = data.list;
            //this.listindicateur2 = data.list;
        },

        deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id_odds);
            const res = await api.createUpdatedata('souscription/delete', fd);
            this.messagesligne(res);
        },

        createUpdatedata: async function () {

            let fd = new FormData();
            fd.append('id_indicateur', this.selectindicateur);
            fd.append('module', 'ODD');
            fd.append('matricule', this.matricule);

            if (this.textetat) {

                const res = await api.createUpdatedata('souscription/update/' + this.id, fd);
                this.text = res.message;
                this.snackbars = true;
            } else {
                const res = await api.createUpdatedata('souscription/add', fd);
                this.text = res.message;
                this.snackbars = true;
            }

            this.clear();
            this.readAll();
        },
        initialize() {
            this.readAll();
        },
        fuseSearch() {
            this.listindicateur2 = [];
            this.listindicateur2 = this.listindicateur.filter(this.filtrerParID);

        },
        filtrerParID: function (obj) {
            // Si c'est un nombre
            if (obj.id_cibles == this.selectcible) {
                //console.log(this.selecteodd);
                return true;
            } else {
                // elementsInvalides++;
                return false;
            }
        },
        nouveau() {
            this.matricule = "";
            this.id_indicateur = "0";
            this.textetat = false;
            this.selectcible = '';
            this.selectindicateur = '';

        },
        Notifications(messages) {
            //this.multiLine true,
            this.snackbar = true;
            this.text = messages;
        },
        messagesligne(res) {
            if (res.status == 200 || res.status == 401) {

                this.textsnackbar = res.message;
                this.timeout = 2000,

                    this.snackbars = true;
                this.readAll();
            }
        },

        clear() {
            this.matricule = "";
            this.id_indicateur = "0";
            this.textetat = false;
            this.selectcible = '';
            this.selectindicateur = '';
            this.textetat = false;
            //this.dialog=false;
        },
        deleteItem(item) {
            this.id_odds = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id = item.id;
            this.selectcible;
            this.selectindicateur;
            //this.code = item.code;
            this.textetat = true;
            this.dialog = true;
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false;
        },

        save() {
            if (this.matricule == '') {
                this.text = "Veuillez Inserer matricule"
                this.snackbar = true;
                return;
            }

            this.createUpdatedata();

        },

        parcoure: function filtrerParID(obj) {
            if (obj.id_cibles == this.selectcible) {
                console.log(obj.libelle);
                return true;
            } else {

                return false;
            }
        },

    },
    mounted() {
        this.readAll();
        this.readcibleAll();
        //this.readindicateurAll();
    },

}
</script>
